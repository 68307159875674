
import { ref, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import ApiService from '@/core/services/ApiService';
import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
import { Actions } from '@/store/enums/StoreEnums';
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import PartnerDetailsCard from '@/components/cards/PartnerDetailsCard.vue';
import carHeader from '@/core/data/car/partnerCarHeader';
import moment from 'moment';

export default {
  name: 'CustomerDetails',
  components: {
    Datatable,
    PartnerDetailsCard,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const { id } = route.params;
    const data = ref<any>([]);
    const pictureLabels = ['รูปถ่ายใบหน้า', 'บัตรประชาชน', 'สำเนาบุ๊คแบงค์'];
    const documentHeader = [
      { name: 'Image', key: 'image', sortable: false },
      { name: 'Description', key: 'title', sortable: true },
    ];
    const currentImage = ref('');
    const labels = [
      { name: 'เพศ / Gender', key: 'gender' },
      { name: 'วัดเกิด / Dath of Birth', key: 'birthdate' },
      { name: 'เบอร์โทรศัพท์ / Phone Number', key: 'phone' },
      { name: 'อีเมล / Email', key: 'email' },
      { name: 'วันสร้างบัญชี / Register Date', key: 'createdAt' },
      {
        name: 'รุ่นอุปกรณ์ / Device Platform',
        key: 'tokens',
        key2: 'devicePlatform',
      },
      {
        name: 'ไอดีอุปกรณ์ / Device ID',
        key: 'tokens',
        key2: 'deviceId',
      },
    ];
    const documents = ref<any>([]);
    const timeFormat = ['birthdate', 'createdAt'];

    store.dispatch(Actions.ADD_BODY_CLASSNAME, 'page-loading');

    onMounted(async () => {
      const res = await ApiService.get(`/customer/${id}`);
      data.value = res.data.data;

      document.title = data.value.name || 'Customer Details';

      setCurrentPageBreadcrumbs(
        'Customer Account',
        [
          { name: 'Account Lists', path: '/customer-account/lists' },
          'Account Details',
        ],
        { name: 'View Verification', path: `/customer-account/verify/${id}` }
      );

      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading');
    });

    const changeImage = (image) => {
      currentImage.value = image;
    };

    return {
      labels,
      data,
      pictureLabels,
      carHeader,
      documentHeader,
      currentImage,
      changeImage,
      documents,
      timeFormat,
      moment,
    };
  },
};
